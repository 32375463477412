<template>
  <div class="pa-4 mt-4">
    <div class="subheading font-weight-bold">
      Existing Members
    </div>
    <div class="px-4 mt-2 mb-5">
      <v-list two-line subheader>
        <v-list-tile v-for="member in lab.members" :key="member.id">
          <v-list-tile-content>
            <v-list-tile-title
              >{{ member.first_name }}
              {{ member.last_name }}</v-list-tile-title
            >
            <v-list-tile-sub-title
              ><a :href="`mailto:${member.email}`">{{
                member.email
              }}</a></v-list-tile-sub-title
            >
          </v-list-tile-content>
          <v-list-tile-action>
            <div>
              <v-chip color="blue lighten-4" v-if="isManager(lab, member.id)">
                Manager
              </v-chip>
              <v-chip color="amber lighten-4">
                {{memberRoles.get(member.member_role)}}
              </v-chip>
              <LabTabMembersChangeManagerDialog
                v-if="isManager(lab, user.id)"
                :lab-id="lab.id"
                :member-id="member.id"
                :disabled="isManager(lab, member.id)"
              />
              <v-btn
                color="error"
                class="text-capitalize ml-3"
                v-on:click="removeMember(lab.id, member.id)"
                v-if="isManager(lab, user.id)"
                :disabled="isManager(lab, member.id)"
              >Remove</v-btn>
            </div>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
    <div class="subheading font-weight-bold">
      Invite New Members
    </div>
    <div class="px-4 mt-2 mb-5">
      <LabInviteForm :lab="lab" />
    </div>
    <div class="subheading font-weight-bold">
      Pending Invites
    </div>
    <div class="px-4 mt-2 mb-5">
      <v-list two-line subheader>
        <v-list-tile v-if="!labInvites.length">
          No pending invites
        </v-list-tile>
        <v-list-tile
          v-for="invite in labInvites"
          :key="invite.id"
          class="py-2"
        >
          <v-list-tile-content>
            <v-list-tile-title
            >{{ invite.invitee_email }}
            </v-list-tile-title>
            <v-list-tile-sub-title
            >Sent: {{ invite.created | formatTime }}</v-list-tile-sub-title
            >
            <v-list-tile-sub-title>
              <div v-if="!invite.accepted">
                Expiration: {{ invite.expires | formatTime }}
                <PopOut
                  v-if="isExpired(invite.expires)"
                  icon="warning"
                  icon-color="orange"
                >
                  While this invite is expired, you can send another invite to this email address.
                </PopOut>
              </div>
              <div v-else>
                Accepted: {{ invite.accepted | formatTime }}
              </div>
            </v-list-tile-sub-title
            >
          </v-list-tile-content>
          <v-list-tile-action>
            <div>
              <v-chip color="green lighten-4" v-if="invite.accepted">
                Accepted
              </v-chip>
              <v-chip color="red lighten-4" v-else-if="isExpired(invite.expires)">
                <span class="pr-2">Expired</span>
                <PopOut
                  v-if="isExpired(invite.expires)"
                  icon="warning"
                >
                  While this invite is expired, you can send another invite to this email address.
                </PopOut>
              </v-chip>
              <v-chip color="amber lighten-4">
                {{memberRoles.get(invite.member_role)}}
              </v-chip>
            </div>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import LabInviteForm from "@bugseq-site/app/src/components/app/LabInviteForm.vue";
import LabTabMembersChangeManagerDialog from "@bugseq-site/app/src/components/app/LabTabMembersChangeManagerDialog.vue";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { components } from '@bugseq-site/app/src/lib/api/api'
import { readUserProfile, readLabInvites } from "@bugseq-site/app/src/store/modules/api/getters";
import {
  dispatchGetLabMembership,
  dispatchGetLabInvites,
  dispatchRemoveLabMember,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
  },
});

enum MemberRole {
  SUBMITTER = "SUBMITTER",
  REVIEWER = "REVIEWER",
}

@Component({
  filters: {
    formatTime,
  },
  components: {
    LabInviteForm,
    LabTabMembersChangeManagerDialog,
    PopOut,
  }
})
export default class LabTabMembers extends ComponentProps {
  private memberRoles: Map<components['schemas']['OrgMemberRole'], string> = new Map([
    [MemberRole.SUBMITTER, "Submitter"],
    [MemberRole.REVIEWER, "Reviewer"],
  ]);

  private async mounted() {
    await dispatchGetLabInvites(this.$store, this.lab.id)
  }

  private isManager(lab: components['schemas']['ListMembershipsResponse'], userId: string): boolean {
    return lab.manager_id === userId;
  }

  get user() {
    return readUserProfile(this.$store);
  }

  get labInvites() {
    return readLabInvites(this.$store).filter(i => i.organization_id === this.lab.id).sort((i1, i2) => i2.created.getTime() - i1.created.getTime())
  }

  private isExpired(expires: Date) {
    return expires < new Date()
  }

  private async removeMember(labId: string, userId: string) {
    await dispatchRemoveLabMember(this.$store, { labId, userId });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }
}
</script>
