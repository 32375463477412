<template>
  <div>
    <div class="subheading my-3">
      Lab members can submit metadata along with sequencing data which is incorporated into various parts of the platform.
    </div>
    <div class="subheading my-3">
      Metadata is restricted to specific keys. Each sample can specify a different value. For example, each sample may have an associated "facility", where the facility is different for each sample.
    </div>
    <div class="subheading my-3">
      Here you can select which metadata keys are accepted. <span class="font-weight-bold">Please be careful not to add any keys that are likely to contain PHI.</span>
    </div>
    <div class="subheading my-3" v-if="lab.metadata_keys.length > 0">
      Existing Keys:
    </div>
    <v-layout>
      <v-chip
        v-for="metadataKey in lab.metadata_keys"
        :key="metadataKey"
        close
        @input="removeMetadataKey(metadataKey)"
      >
        {{ metadataKey }}
      </v-chip>
    </v-layout>
    <div class="subheading mt-4">
      <div class="font-weight-bold mb-3">
        Add New Keys
      </div>
      <div>
        Keys must start with a letter, can only container letters/numbers/_, and must be 24 characters or less.
      </div>
      <v-alert
        :value="lab.metadata_keys.length === metadataKeyLimit"
        class="font-weight-bold my-3"
        type="warning"
        outline
      >
        Only {{ metadataKeyLimit }} keys can be specified, no more can be added
      </v-alert>
    </div>
    <v-form>
      <v-layout class="mb-2">
        <v-text-field
          name="Metadata Key"
          placeholder="facility_id"
          v-model="newMetadataKey"
          v-validate="'max:24|regex:^[a-zA-Z][a-zA-Z0-9_]*$'"
          :error-messages="errors.first('Metadata Key')"
        ></v-text-field>
        <v-btn
          class="text-capitalize"
          @click="addMetadataKey(newMetadataKey)"
          :disabled="lab.metadata_keys.length === metadataKeyLimit || errors.items.length > 0"
        >Add</v-btn>
      </v-layout>
      <v-btn
        :disabled="!newMetadataKeyDirty || disabled"
        class="ml-0 text-capitalize"
        @click="updateMetadataKeys()"
        color="primary"
      >
        Save
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
    disabled: Boolean,
  },
});

@Component({
  components: {
    PopOut,
  },
})
export default class LabTabAnalysisConfigurationMetadataKeys extends ComponentProps {
  private newMetadataKey: string = ""
  private newMetadataKeyDirty: boolean = false
  private metadataKeyLimit: number = 5

  private addMetadataKey(metadataKey: string) {
    if (!metadataKey) {
      return
    }
    this.lab.metadata_keys.push(metadataKey)
    this.newMetadataKey = ""
    this.newMetadataKeyDirty = true
  }

  private removeMetadataKey(metadataKey: string) {
    this.lab.metadata_keys = this.lab.metadata_keys.filter(k => k !== metadataKey)
    this.newMetadataKeyDirty = true
  }

  private async updateMetadataKeys() {
    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: { metadata_keys: this.lab.metadata_keys },
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members

    this.newMetadataKeyDirty = false
    this.newMetadataKey = ""
  }
}
</script>
