<template>
  <div>
    <div class="subheading my-3">
      These parameters will impact your analysis and results when you submit data.
    </div>
    <v-form>
      <div
        v-for="field in paramFields.values()"
        :key="field.name"
        class="my-2"
      >
        <div class="subheading font-weight-bold">{{ field.headingText }}</div>
        <div v-if="field.helptext" class ="subheading my-2" v-html="field.helptext" />
        <v-text-field
          :name="field.name"
          type="number"
          :placeholder="field.placeholder"
          :min="field.min"
          :step="field.step"
          :suffix="field.suffix"
          v-model="field.model"
          :v-validate="field.validate"
          :error-messages="errors.first(field.name)"
          class="input-field"
        ></v-text-field>
      </div>
      <v-btn
        class="ml-0 text-capitalize"
        @click="updateAnalysisParameters()"
        color="primary"
        :disabled="errors.items.length > 0 || disabled"
      >
        Save
      </v-btn>
      <div class="mt-4 mb-2">
        <div class="subheading font-weight-bold">Cluster Alerting</div>
        <div class ="subheading my-2">
          Detect when a submitted sample is added to an existing cluster. You can configure the sensitivity of the alerting in terms of allele cutoffs as described in the <a href="https://docs.bugseq.com/output/outbreak-analysis/#how-are-clusters-identified" target="_blank">Cluster Addresses documentation</a>. For example, if you specify a cutoff of 50 alleles, you will receive an alert when a submitted sample is ≤ 50 alleles from a previously submitted sample (and accordingly shares has all <code>x</code> in common for cluster code <code>x.x.x.x.a.b.c</code>).
        </div>
        <div class="ml-4 mt-4">
          <div class="subheading font-weight-bold">Default Alert Threshold</div>
          <div class ="subheading my-2">
            If configured, summary reports will include an alert when a new sample is added to an existing cluster for any organism. Organism-specific cutoffs can be configured below.
          </div>
          <v-select
            label="Default Cutoff for All Organisms"
            v-model="defaultOutbreakAlleleCutoff"
            :items="outbreakAlleleCutoffs"
          ></v-select>
          <div class="subheading font-weight-bold mt-4">Per-Organism Alert Thresholds</div>
          <v-layout class="mb-2">
            <v-autocomplete
              ref="taxidSearchAutocomplete"
              :search-input.sync="taxidSearchInput"
              :items="taxidSearchItems"
              :loading="taxidSearchLoading"
              item-value="taxid"
              @update:searchInput="taxidSearch"
              @input="taxidSearchInputChanged"
              hide-no-data
              hide-selected
              label="Add Per-Organism Alerting"
              prepend-icon="mdi-database-search"
              return-object
              :disabled="disabled"
            ></v-autocomplete>
          </v-layout>
          <v-layout column class="mb-2 ml-4">
            <v-flex xs12 v-for="parameter in perTaxidOutbreakParameters" class="my-4">
              <div class="subheading font-weight-bold">{{ parameter.species_name }} (taxid: {{ parameter.species_taxid }})</div>
              <v-select
                label="Cutoff"
                :items="outbreakAlleleCutoffs"
                v-model="parameter.new_sample_alert_cutoff"
              ></v-select>
              <v-btn
                class="ml-0 text-capitalize"
                @click="removePerTaxidOutbreakParameters(parameter.species_taxid)"
              >Remove</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>
      <v-btn
        class="ml-0 text-capitalize"
        @click="updateAnalysisParameters()"
        color="primary"
        :disabled="errors.items.length > 0 || disabled"
      >
        Save
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUpdateLab,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { debounce } from "@bugseq-site/shared/src/lib/utils";
import { components } from '@bugseq-site/app/src/lib/api/api'
import { ComboboxField } from "@bugseq-site/app/src/lib/types";

const ComponentProps = Vue.extend({
  props: {
    lab: Object,
    disabled: Boolean,
  },
});

interface Parameter {
  model: string; // html input models are strings :(
  headingText: string;
  name: string;
  placeholder: string;

  helptext?: string;

  min?: number;
  step?: number;
  suffix?: string;
  validate?: string;
}

interface TaxidSearchItem {
  text: string;
  taxid: number;
  taxname: string;
  disabled: boolean;
}

@Component
export default class LabTabAnalysisConfigurationAnalysisParameters extends ComponentProps {
  private paramFields: Map<string, Parameter> = new Map([
    [
      "percent_identity_to_reference_genome_threshold",
      {
        model: "",
        headingText: "Percent Identity to Reference Genome Pass/Fail Threshold:",
        name: "Percent Identity to Reference Genome",
        helptext: "Only applies to bacterial isolates. Pass/fail reported in sample report executive summary.",
        placeholder: "95",
        min: 1,
        step: 1,
        suffix: "%",
        validate: "'between:1,100'",
      },
    ],
    [
      "median_coverage_of_reference_genome_threshold",
      {
        model: "",
        headingText: "Median Coverage of Reference Genome Pass/Fail Threshold:",
        name: "Median Coverage of Reference Genome",
        helptext: "Only applies to bacterial isolates. Pass/fail reported in sample report executive summary.",
        placeholder: "40",
        min: 1,
        step: 1,
        suffix: "X",
        validate: "'between:1,100'",
      },
    ],
    [
      "assembly_completeness_threshold",
      {
        model: "",
        headingText: "Assembly Completeness Pass/Fail Threshold:",
        name: "Assembly Completeness",
        helptext: "Only applies to bacterial isolates. Pass/fail reported in sample report executive summary.",
        placeholder: "90",
        min: 1,
        step: 1,
        suffix: "%",
        validate: "'between:1,100'",
      },
    ],
    [
      "assembly_duplication_threshold",
      {
        model: "",
        headingText: "Assembly Duplication Pass/Fail Threshold:",
        name: "Assembly Duplication",
        helptext: "Only applies to bacterial isolates. Pass/fail reported in sample report executive summary.",
        placeholder: "5",
        min: 1,
        step: 1,
        suffix: "%",
        validate: "'between:1,100'",
      },
    ],
    [
      "number_of_contigs_warn_threshold",
      {
        model: "",
        headingText: "Number of Contigs Warning Threshold:",
        name: "Number of Contigs (Warning)",
        helptext: "Only applies to bacterial isolates. Warning reported in sample report executive summary if more contigs are assembled than the threshold (but fewer than the fail threshold, otherwise fail will be reported).",
        placeholder: "200",
        min: 1,
        step: 1,
        validate: "'min_value:1'",
      },
    ],
    [
      "number_of_contigs_fail_threshold",
      {
        model: "",
        headingText: "Number of Contigs Fail Threshold:",
        name: "Number of Contigs (Fail)",
        helptext: "Only applies to bacterial isolates. Fail reported in sample report executive summary if more contigs than threshold are assembled.",
        placeholder: "500",
        min: 1,
        step: 1,
        validate: "'min_value:1'",
      },
    ],
    [
      "length_qc_tukeys_k_warn_threshold",
      {
        model: "",
        headingText: "Assembly Length Outlier Detection Warning Threshold (Tukey's k Value):",
        name: "Assembly Length Tukey's k (Warning)",
        helptext: 'Detects outliers for assembly length relative to the species\' distribution using <a href="https://en.wikipedia.org/wiki/Outlier#Tukey\'s_fences" target="_blank"> Tukey\'s fences test</a>. Warning reported in sample executive summary if the genome is found to fall outside <code>[Q1-k(Q3-Q1), Q3+k(Q3-Q1)]</code>',
        placeholder: "1.5",
        min: 0,
        step: 0.1,
        validate: "'min_value:0'",
      },
    ],
    [
      "length_qc_tukeys_k_fail_threshold",
      {
        model: "",
        headingText: "Assembly Length Outlier Detection Fail Threshold (Tukey's k Value):",
        name: "Assembly Length Tukey's k (Fail)",
        helptext: 'Detects outliers for assembly length relative to the species\' distribution using <a href="https://en.wikipedia.org/wiki/Outlier#Tukey\'s_fences" target="_blank"> Tukey\'s fences test</a>. Fail reported in sample executive summary if the genome is found to fall outside <code>[Q1-k(Q3-Q1), Q3+k(Q3-Q1)]</code>',
        placeholder: "3",
        min: 0,
        step: 0.1,
        validate: "'min_value:0'",
      },
    ],
    [
      "gc_content_qc_tukeys_k_warn_threshold",
      {
        model: "",
        headingText: "Assembly GC-Content Outlier Detection Warning Threshold (Tukey's k Value):",
        name: "Assembly GC Content Tukey's k (Warning)",
        helptext: 'Detects outliers for assembly GC-content relative to the species\' distribution using <a href="https://en.wikipedia.org/wiki/Outlier#Tukey\'s_fences" target="_blank"> Tukey\'s fences test</a>. Warning reported in sample executive summary if the genome is found to fall outside <code>[Q1-k(Q3-Q1), Q3+k(Q3-Q1)]</code>',
        placeholder: "1.5",
        min: 0,
        step: 0.1,
        validate: "'min_value:0'",
      },
    ],
    [
      "gc_content_qc_tukeys_k_fail_threshold",
      {
        model: "",
        headingText: "Assembly GC-Content Outlier Detection Fail Threshold (Tukey's k Value):",
        name: "Assembly GC Content Tukey's k (Fail)",
        helptext: 'Detects outliers for assembly GC-content relative to the species\' distribution using <a href="https://en.wikipedia.org/wiki/Outlier#Tukey\'s_fences" target="_blank"> Tukey\'s fences test</a>. Warning reported in sample executive summary if the genome is found to fall outside <code>[Q1-k(Q3-Q1), Q3+k(Q3-Q1)]</code>',
        placeholder: "3",
        min: 0,
        step: 0.1,
        validate: "'min_value:0'",
      },
    ],
  ])

  private defaultOutbreakAlleleCutoff: components["schemas"]["OutbreakAlleleCutoff"] = "OUTBREAK_ALLELE_CUTOFF_UNSPECIFIED"
  private selectedOutbreakOrganism: number | null = null
  private perTaxidOutbreakParameters: components["schemas"]["PerTaxidOutbreakParameters"][] = []
  private outbreakAlleleCutoffs: ComboboxField<components["schemas"]["OutbreakAlleleCutoff"]>[] = [
    { text: "(unspecified)", value: "OUTBREAK_ALLELE_CUTOFF_UNSPECIFIED" },
    { text: "5 alleles", value: "OUTBREAK_ALLELE_CUTOFF_5" },
    { text: "10 alleles", value: "OUTBREAK_ALLELE_CUTOFF_10" },
    { text: "20 alleles", value: "OUTBREAK_ALLELE_CUTOFF_20" },
    { text: "50 alleles", value: "OUTBREAK_ALLELE_CUTOFF_50" },
    { text: "100 alleles", value: "OUTBREAK_ALLELE_CUTOFF_100" },
    { text: "200 alleles", value: "OUTBREAK_ALLELE_CUTOFF_200" },
    { text: "1000 alleles", value: "OUTBREAK_ALLELE_CUTOFF_1000" },
  ]

  private taxidSearchInput = ""
  private taxidSearchItems: TaxidSearchItem[] = []
  private taxidSearchLoading = false

  private mounted() {
    for (let [key, val] of this.paramFields.entries()) {
      if (this.lab[key] !== null) {
        val.model = this.lab[key]
      }
    }

    // force ui update
    this.paramFields = new Map(this.paramFields)

    this.defaultOutbreakAlleleCutoff = this.lab.default_outbreak_allele_cutoff
    this.perTaxidOutbreakParameters = this.lab.per_taxid_outbreak_parameters.sort((i0, i1) => i0.species_name.localeCompare(i1.species_name));
  }

  @debounce(300)
  private async taxidSearch(q: string | null) {
    if (!q) {
      if (this.taxidSearchItems.length > 0) {
          this.taxidSearchItems = []
      }
      return
    }

    this.taxidSearchLoading = true

    try {
      const response = await fetch(
        `https://api.ncbi.nlm.nih.gov/datasets/v2/taxonomy/taxon_suggest/${q}?tax_rank_filter=species`,
        {
          method: "GET",
          headers: {
            "accept": "application/json",
          },
        }
      )

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const parsed = await response.json();
      this.taxidSearchItems = parsed.sci_name_and_ids.filter(
        p => p.rank === "SPECIES"
      ).map(
        p => ({
          text: `${p.sci_name} (taxid: ${p.tax_id})`,
          taxid: p.tax_id,
          taxname: p.sci_name,
          disabled: this.perTaxidOutbreakParameters.find(existing => existing.species_taxid === p.tax_id) !== undefined,
        })
      )
    } finally {
      this.taxidSearchLoading = false
    }
  }

  private taxidSearchInputChanged(searchItem: TaxidSearchItem | undefined) {
    if (searchItem === undefined) {
      return
    }

    this.perTaxidOutbreakParameters.push({
      species_taxid: searchItem.taxid,
      species_name: searchItem.taxname,
      new_sample_alert_cutoff: "OUTBREAK_ALLELE_CUTOFF_UNSPECIFIED",
    });
    this.perTaxidOutbreakParameters.sort((i0, i1) => i0.species_name.localeCompare(i1.species_name));

    const autocompleteRef = this.$refs.taxidSearchAutocomplete! as any
    autocompleteRef.reset()
    autocompleteRef.blur()
  }

  private removePerTaxidOutbreakParameters(taxid: number) {
    this.perTaxidOutbreakParameters = this.perTaxidOutbreakParameters.filter(p => p.species_taxid !== taxid)
  }

  private async updateAnalysisParameters() {
    const params = {
      default_outbreak_allele_cutoff: this.defaultOutbreakAlleleCutoff,
      per_taxid_outbreak_parameters: this.perTaxidOutbreakParameters,
    }
    for (let [key, val] of this.paramFields.entries()) {
      params[key] = val.model !== "" ? parseFloat(val.model) : null
    }

    await dispatchUpdateLab(this.$store, {
      id: this.lab.id,
      lab: params,
    });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
  }
}
</script>

<style scoped>
.input-field {
  max-width: 30em;
}
</style>
