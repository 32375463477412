<template>
  <v-dialog
    v-model="showDialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="warning"
        class="text-capitalize ml-3 make-manager-button"
        v-on="on"
        :disabled="disabled"
      >Make Manager</v-btn>
    </template>

    <v-card class="pa-4">
      <div class="headline">
        Warning
      </div>

      <div class="my-4">
        If you change the manager, you will no longer be able to make modifications to the lab. Do you want to continue?
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="text-capitalize"
          flat
          @click="showDialog = false"
        >
          No
        </v-btn>
        <v-btn
          color="warning"
          class="text-capitalize"
          @click="makeManager()"
        >
          Yes, change manager
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchGetLabMembership,
  dispatchUpdateLab,
} from "@bugseq-site/app/src/store/modules/api/actions";

const ComponentProps = Vue.extend({
  props: {
    labId: String,
    memberId: String,
    disabled: Boolean,
  },
});

@Component
export default class LabTabMembersChangeManagerDialog extends ComponentProps {
  private showDialog = false;

  private async makeManager() {
    await dispatchUpdateLab(this.$store, { id: this.labId, lab: { manager_id: this.memberId } });
    await dispatchGetLabMembership(this.$store); // refresh, because the resp of create doesn't have members
    this.showDialog = false
  }
}
</script>

<style scoped>
.make-manager-button {
  padding: 4px 20px;
}
</style>
